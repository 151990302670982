<script lang="ts" setup>
import { PropType } from 'vue';
import { EmitsEnum } from '~/constants/emits';
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys';
import { SpSvg } from '~~/src/autogen/SpSvg';
import { IMessageWithCondition } from '~~/src/types/IMessageWithCondition';

const props = defineProps({
    value: { type: String, default: '' },
    placeholder: { type: String as PropType<TranslationKey> },
    errorCondition: { type: Object as PropType<IMessageWithCondition<any>> },
    label: { type: String as PropType<TranslationKey>, default: undefined },
    disabled: { type: Boolean, default: false }
})
const { value, placeholder: _placeholder, errorCondition, label, disabled } = toRefs(props)

const emit = defineEmits([EmitsEnum.Change, EmitsEnum.FocusOut])
const onChange = (val: any) => {
    const text = val.target.value
    value.value = text
    emit(EmitsEnum.Change, text)
}
const onFocusOut = () => emit(EmitsEnum.FocusOut, value.value)

const placeholder = computed(() => useLocale().translate(_placeholder?.value || TranslationKeys.CHOSE_AN_OPTION).toString())
const isError = computed(() => errorCondition?.value != undefined && errorCondition.value.condition(value.value.toString()))

const bgColor = computed(() => disabled.value ? 'bg-dark-blue-100' : 'bg-main-blue-100')
const outlineColor = computed(() => isError.value ? 'border-red-400 outline-red-400 border-2' : 'border-main-blue-200 outline-main-blue-200')
const textColor = computed(() => disabled.value ? 'text-dark-blue-400' : 'text-black')

defineExpose({
    hasError: () => isError.value
})
</script>

<template>
    <div class="flex flex-col gap-1">
        <CommonLabel :text="label">
            <div class="relative">
                <CommonIcon v-if="isError" :icon-name="SpSvg.BasicErrorCircle" class="absolute top-1/2 right-3"
                    style="transform: translateY(-50%)" fill="fill-red-400" />
                <input type="text" :value="value" :placeholder="placeholder" :disabled="disabled"
                    class="w-full p-2 text-sm border rounded-md border-1"
                    :class="[bgColor, outlineColor, textColor].join(' ')" @input="onChange" @change="onChange"
                    @focusout="onFocusOut" />
            </div>
            <CommonText class="text-red-500" v-if="isError" :text="errorCondition!.message" />
        </CommonLabel>
    </div>
</template>